#zone-video {
  position: relative;
  height: calc(90vh - 17rem);
  width: 100%;
  z-index: 0;
  overflow: hidden;
  background: #70777c; }
  @media (max-width: 1200px) {
    #zone-video {
      padding: 0; } }
  @media (max-width: 1200px) {
    .pageType #zone-video {
      height: 60rem;
      top: 3rem;
      position: absolute;
      width: 100%;
      overflow: hidden; } }
  @media (max-width: 800px) {
    #zone-video {
      height: calc(55vh - 17rem); } }
  #zone-video .load {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 8rem;
    padding: 0; }
    #zone-video .load .cms_bloc_media {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: stretch;
      padding: 0;
      opacity: 1;
      transform: translateZ(0) scale(1);
      transition: opacity 0.6s linear 1.2s, transform 0.6s ease-out 1.2s, z-index 0s ease-out 1.9s; }
      #zone-video .load .cms_bloc_media .media {
        background-size: cover;
        width: 100%;
        background-position: center center;
        position: relative; }
        #zone-video .load .cms_bloc_media .media::before {
          content: '';
          display: block;
          position: absolute;
          z-index: 20;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2); }
    #zone-video .load .cms_bloc_text {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 30;
      opacity: 1;
      width: calc(99rem * 0.75);
      color: white; }
      @media (max-width: 800px) {
        #zone-video .load .cms_bloc_text {
          width: 100%; } }
      #zone-video .load .cms_bloc_text > * {
        color: inherit; }
      #zone-video .load .cms_bloc_text h2 {
        color: white;
        font-size: 4rem;
        font-weight: 300;
        background: rgba(115, 142, 154, 0.8);
        text-transform: uppercase;
        display: inline-block;
        padding: .3rem 1rem .8rem; }
        @media (max-width: 800px) {
          #zone-video .load .cms_bloc_text h2 {
            font-size: 2.3rem; } }
      #zone-video .load .cms_bloc_text p {
        font-size: 1.5rem; }
      #zone-video .load .cms_bloc_text .bouton_bleu {
        display: inline-block;
        color: white;
        text-transform: uppercase;
        background: #0b3087;
        border: 0.15em solid #0b3087;
        text-decoration: none;
        margin: 0.5em 0;
        padding: 0.7em 2.4em;
        font-size: 1.6rem;
        border-radius: 2em;
        z-index: 1;
        transition: all .25s ease-out;
        transition-property: border, background, color; }
        #zone-video .load .cms_bloc_text .bouton_bleu:hover {
          background: rgba(11, 48, 135, 0.3);
          color: white; }
  #zone-video.show .load .cms_bloc_media {
    opacity: 0;
    transform: translateZ(0) scale(1.1);
    z-index: 0; }
  #zone-video > .inner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%; }
    #zone-video > .inner::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2); }
    #zone-video > .inner iframe {
      position: relative;
      z-index: 1; }
